<template>
  <div class="content" style="padding-top: 50px">
    <div class="list-nav">
      <h4>产品展示</h4>
      <div class="tags">
        <span
          class="all"
          :class="cate_id === all_cate ? 'active' : ''"
          @click="setCate(all_cate)"
        >所有</span
        >
        <span
          @click="setCate(item.id)"
          v-for="item in cate"
          :key="item.id"
          :class="item.id === cate_id ? 'active' : ''"
        >{{ item.title }}</span
        >
      </div>
    </div>
    <div class="list-box">
      <div class="view-content">
        <article
          class="article"
          :title="item.title"
          v-for="item in data"
          :key="item.id"
        >
          <router-link :to="{path:'/product/item',query: {id: item.id}}">
            <div class="img">
              <img :src="item.cover" :title="item.title" :alt="item.title" />
            </div>
            <div class="title">
              <span>{{ item.cate_title }}</span
              >{{ item.title }}
            </div>
            <div class="date">{{ item.create_time }}</div>
          </router-link>
        </article>
      </div>

      <div class="more" @click="more()">
        <span>查看更多</span>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "vue3-eventbus";
import state from "../../store/state";
import article from "../../api/article";
import { message } from "ant-design-vue/es";

export default {
  name: "ProductList",
  data() {
    return {
      cate: [],
      page: 1,
      limit: 6,
      data: [],
      cate_id: 2,
      all_cate: 2
    };
  },
  mounted() {
    document.title = "产品列表 - "+state.websiteSet.website.title

    const that = this;
    eventBus.on("product_cate", (val) => {
      that.cate = val;
      this.all_cate = val[0].pid;
    });
    this.getArticles();
  },
  methods: {
    //获取12个文章
    getArticles() {
      const that = this;
      article
        .getArticles(
          state.websiteSet.website.id,
          that.cate_id,
          that.page,
          that.limit
        )
        .then((res) => {
          if (res.code === 0 && res.data.data.length > 0) {
            let data = res.data.data;
            data.forEach(function(item) {
              that.data.push(item);
            });
            that.page = that.page + 1;
          } else {
            message.warning("没有更多了");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setCate(cate_id) {
      this.cate_id = cate_id;
      this.page = 1;
      this.data = [];
      this.getArticles();
    },
    more() {
      this.getArticles();
    }
  }
};
</script>

<style scoped>
.list-nav {
  margin: 1rem auto;
  max-width: 1170px;
  height: 4rem;
}

.list-nav h4 {
  display: inline-block;
  float: left;
  font-size: 32px;
  font-weight: lighter;
  line-height: 4rem;
}

.list-nav .tags {
  height: 4rem;
  display: inline-block;
  float: right;
}

.list-nav .tags span {
  height: 4rem;
  line-height: 5rem;
  display: inline-block;
  min-width: 4rem;
  text-align: center;
  margin-left: 10px;
  color: #9b9b9b;
}

.list-nav .tags .active {
  font-weight: bolder;
  color: #000000;
}

.list-box {
  margin: 50px 0;
  position: relative;
}

.view-content {
  max-width: 1170px;
  margin: 0 auto;
}

.article {
  width: 30%;
  display: inline-block;
  margin: 1.66%;
}

.article .img {
  margin-bottom: 25px;
}

.article .img img {
  height: 220px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  font-size: 0;
  display: block;
  max-width: 100%;
}

.article .title {
  height: 80px;
  overflow: hidden;
  margin-bottom: 15px;
  font-size: 28px;
  line-height: 40px;
  color: #000000;
  font-weight: lighter;
}

.article .title span {
  font-size: 14px;
  padding: 5px 10px;
  line-height: 28px;
  border: 1px solid #3f3f3f;
  vertical-align: middle;
  margin-right: 10px;
  font-weight: bolder;
}

.article .date {
  color: #9b9b9b;
  letter-spacing: 2px;
  font-size: 14px;
  line-height: 16px;
}

.more {
  left: 200px;
  text-align: center;
  width: 100%;
  margin: 80px auto;
}

.more span {
  border: 1px solid #000;
  cursor: pointer;
  height: 25px;
  line-height: 25px;
  margin-top: 14px;
  text-align: center;
  padding: 15px 40px;
  font-size: 12px;
  font-weight: bold;
}
</style>
